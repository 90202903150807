import { useState, useEffect } from 'react'
import { LockClosedIcon, LockOpenIcon } from '@heroicons/react/solid'
import loginBkg from '../assets/login_bkg.jpg'

export default function Login(props) {

    const [isButtonDisabled, setIsButtonDisabled] = useState(true);
    const [enteredPassword, setEnteredPassword] = useState('');

    const passwordUpdatedHandler = (e) => {
        setEnteredPassword(e.target.value);
    }

    useEffect(() => {
        if(enteredPassword === 'GetTaichi2022') {
            setIsButtonDisabled(false);
        }
    }, [enteredPassword])

    const loginHandler = (event) => {
        event.preventDefault();
        if(enteredPassword === 'GetTaichi2022'){            
            props.onAuthenticate();
        }
    }

    return (
        <>
            <div className="h-screen flex">
                <div className="flex-1 flex flex-col justify-center py-12 px-4 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
                    <div className="mx-auto w-full max-w-sm lg:w-96">
                        <div className='flex flex-col items-center text-center'>
                            <svg className='h-12 w-12 text-gray-500' xmlns="http://www.w3.org/2000/svg" fill="currentColor" stroke="currentColor" viewBox="0 0 442.88 340.54"><g id="Layer_2" data-name="Layer 2"><g id="Layer_1-2" data-name="Layer 1"><path fill="c" d="M0,181.92C59.6,132,118.44,80.57,179.14,32.65,208.68,9.33,243.23-.19,279.08,0c48.21.26,96.42.07,144.63.07h19.17C436.4,6.3,433.07,9.88,429.45,13Q351.92,78.5,274.28,143.89c-32.65,27.37-69.19,41.87-108.69,42.72-51.9,1.12-103.84.3-155.75.26-3,0-6-.51-9.07-.78C.51,184.7.26,183.31,0,181.92Z" /><path fill="currentColor" d="M2.57,228c47.85,0,95.7.45,143.55-.12,48.76-.59,95.71,9.25,140.71,32.43,32.09,16.53,64.37,32.55,96.54,48.86,17.05,8.64,34.06,17.4,54.61,27.91-6.23,1.23-9.17,2.32-12.1,2.32-52.4.09-104.95,3.06-157.13-1a335.68,335.68,0,0,1-103.51-24.9C112.45,291.18,61.61,262.12,10,235.85c-2.72-1.38-5.26-3.3-7.88-5C2.24,229.92,2.41,229,2.57,228Z" /></g></g></svg>
                            <h2 className="mt-6 text-3xl font-extrabold text-gray-900">Sign in to view real-time inventory</h2>
                            <p className="mt-2 text-sm text-gray-600">
                            </p>
                        </div>

                        <div className="mt-8">
                            <div className="mt-6">
                                <form className="space-y-6">
                                    <div className="space-y-1">
                                        <label htmlFor="password" className="block text-sm font-medium text-gray-700">
                                            Password
                                        </label>
                                        <div className="mt-1">
                                            <input
                                                id="password"
                                                name="password"
                                                type="password"
                                                autoComplete="current-password"
                                                required
                                                className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-gray-500 focus:border-gray-500 sm:text-sm"
                                                onChange={passwordUpdatedHandler}
                                            />
                                        </div>
                                    </div>

                                    <div className="flex items-center justify-between">
                                        <div className="hidden">
                                            <input
                                                id="remember-me"
                                                name="remember-me"
                                                type="checkbox"
                                                className="h-4 w-4 text-gray-600 focus:ring-gray-500 border-gray-300 rounded"
                                            />
                                            <label htmlFor="remember-me" className="ml-2 block text-sm text-gray-900">
                                                Remember me
                                            </label>
                                        </div>

                                        <div className="text-sm">
                                            <span className="font-medium text-gray-600 hover:text-gray-500">
                                                Need access assistance? <a href="mailto: shipping@motoliberty.com" className="text-cyan-600"> Get in touch!</a>
                                            </span>
                                        </div>
                                    </div>

                                    <div>
                                        <button                                              
                                            className={`group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white 
                                            ${isButtonDisabled ? "bg-gray-500" : "bg-cyan-600 hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cyan-500"}`}
                                            disabled={isButtonDisabled}
                                            onClick={loginHandler}
                                        >
                                            <span className="absolute left-0 inset-y-0 flex items-center pl-3">
                                                {isButtonDisabled && <LockClosedIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />}
                                                {!isButtonDisabled && <LockOpenIcon className="h-5 w-5 text-cyan-500 group-hover:text-cyan-400" aria-hidden="true" />}
                                            </span>
                                            Sign in
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="hidden lg:block relative w-0 flex-1">
                    <img
                        className="absolute inset-0 h-full w-full object-cover"
                        src={loginBkg}
                        alt=""
                    />
                </div>
            </div>
        </>
    )
}