import { useState, useCallback, useEffect } from 'react';
import { ExportToCsv } from 'export-to-csv';
import { DocumentDownloadIcon, SearchIcon, RefreshIcon, ExclamationIcon } from '@heroicons/react/solid'

const params = new Proxy(new URLSearchParams(window.location.search), {
    get: (searchParams, prop) => searchParams.get(prop),
  });
  // Get the value of "some_key" in eg "https://example.com/?some_key=some_value"
  let value = params.next; // "some_value"

let skip = +value ?? 0;

const API_KEY = "6FA67871A16D1BE9D46C91811BF7F527C2A18666E71BC9C2095EEBE4F098AB18"
const company_id = "bc3159df-31ff-46db-b9d8-81acb76ae8c4"
let url = `https://cloudapi.inflowinventory.com/${company_id}/products?include=Category&include=prices&include=inventoryLines&filter[isActive]=true&includeCount=true&count=100&skip=${skip}`

export default function Data() {

    const [inventory, setInventory] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState('');
    const [enteredSearch, setEnteredSearch] = useState('');
    const [search, setSearch] = useState('');

    const getInventory = useCallback(async () => {
        setIsLoading(true);             
            try {
                const response = await fetch(search ? url + '&filter[smart]=' + search : url, {
                    headers: {
                        'Authorization': 'Bearer ' + API_KEY,
                        'Content-Type': 'application/json',
                        'Accept': 'application/json;version=2021-04-26',
                    }
                });
                
                if (response.errors || response.status !== 200) {                
                    setError('Status returned was not OK');
                    throw new Error(response.errors);
                }
    
                const data = await response.json();
                
                setInventory(data.filter(item => item.totalQuantityOnHand && parseInt(item.totalQuantityOnHand) > 0));
    
            } catch (error) {            
                console.log(error)
                setError(error);
            }
       
        setIsLoading(false);
        setError(null);
    }, [search]);

    const csvExportHandler = useCallback(async () => {
        const csvExportOptions = {
            fieldSeparator: ',',
            quoteStrings: '"',
            decimalSeparator: '.',
            showLabels: true,
            showTitle: true,
            title: 'Taichi USA Inventory',
            useTextFile: false,
            useBom: true,
            //useKeysAsHeaders: true,
            headers: ['name', 'barcode', 'description', 'category', 'color', 'size', 'total qty onhand']
        };
        if (inventory) {
            const csvExporter = new ExportToCsv(csvExportOptions);
            const csvData = inventory.map(item => {
                return {
                    'name': item.name,
                    'barcode': item.barcode,
                    'description': item.description,
                    'category': item.category.name,
                    'color': item.customFields.custom1,
                    'size': item.customFields.custom2,
                    'totalOnHand': Math.round(item.totalQuantityOnHand)
                }
            })
            csvExporter.generateCsv(csvData);
        }
    }, [inventory]);

    const onInputChange = e => {
        setEnteredSearch(e.target.value)
    }

    useEffect(() => {
        getInventory();
    }, [getInventory]);

    useEffect(() => {
        const identifier = setTimeout(() => {
            //console.log('set search value');
            setSearch(enteredSearch);
        }, 500);
        return () => {
            //console.log('cleanup');
            clearTimeout(identifier);
        }
    }, [enteredSearch]);

    function classNames(...classes) {
        return classes.filter(Boolean).join(' ')
    }

    return (
        <div className="px-4 sm:px-6 lg:px-8">
            <div className="sm:flex sm:items-center">
                <div className="sm:flex-auto">
                    <div className="flex-1 flex justify-center lg:justify-between">
                        <div className="max-w-lg w-full lg:max-w-xs">
                            <label htmlFor="search" className="sr-only">
                                Search
                            </label>
                            <div className="relative text-gray-400 focus-within:text-gray-600">
                                <div className="pointer-events-none absolute inset-y-0 left-0 pl-3 flex items-center">
                                    <SearchIcon className="h-5 w-5" aria-hidden="true" />
                                </div>
                                <input
                                    id="search"
                                    className="block w-full bg-white py-2 pl-10 pr-3 border border-gray-200 rounded-md leading-5 text-gray-900 placeholder-gray-500 shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-600 focus:ring-white focus:border-white sm:text-sm"
                                    placeholder="Search"
                                    type="search"
                                    name="search"
                                    value={enteredSearch}
                                    onChange={onInputChange}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
                    <button
                        type="button"
                        className="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-cyan-600 hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cyan-500"
                        onClick={() => window.open('https://taichi-csv-feed.mlinventory.workers.dev/')}
                    >
                        <DocumentDownloadIcon className="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
                        Export items to csv
                    </button>
                </div>
            </div>
            <div className="mt-8 flex flex-col">                
                {isLoading && <p className='inline-flex items-center'><RefreshIcon className='h-10 w-10 animate-spin text-gray-400 mr-4' /> Fetching data, one moment</p>}
                {!isLoading && error && <p className='inline-flex items-center'><ExclamationIcon className='h-10 w-10 text-gray-400 mr-4' /> Whoops, something is amiss</p>}
                {!isLoading && !error && <div className="-my-2 -mx-4 sm:-mx-6 lg:-mx-8">
                    <div className="inline-block min-w-full py-2 align-middle">
                        <div className="shadow-sm ring-1 ring-black ring-opacity-5">
                            <table className="min-w-full border-separate" style={{ borderSpacing: 0 }}>
                                <thead className="bg-gray-50">
                                    <tr>
                                        <th
                                            scope="col"
                                            className="sticky top-0 z-10 border-b border-gray-300 bg-gray-50 bg-opacity-75 py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter sm:pl-6 lg:pl-8"
                                        >
                                            Name
                                        </th>
                                        <th
                                            scope="col"
                                            className="sticky top-0 z-10 hidden border-b border-gray-300 bg-gray-50 bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter sm:table-cell"
                                        >
                                            Description
                                        </th>
                                        <th
                                            scope="col"
                                            className="sticky top-0 z-10 hidden border-b border-gray-300 bg-gray-50 bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter lg:table-cell"
                                        >
                                            Category
                                        </th>
                                        <th
                                            scope="col"
                                            className="sticky top-0 z-10 border-b border-gray-300 bg-gray-50 bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter"
                                        >
                                            Color
                                        </th>
                                        <th
                                            scope="col"
                                            className="sticky top-0 z-10 border-b border-gray-300 bg-gray-50 bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter"
                                        >
                                            Size
                                        </th>
                                        <th
                                            scope="col"
                                            className="sticky top-0 z-10 border-b border-gray-300 bg-gray-50 bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter"
                                        >
                                            Qty OnHand
                                        </th>

                                    </tr>
                                </thead>
                                <tbody className="bg-white">
                                    {inventory.map((inventory, inventoryIdx) => (
                                        <tr key={inventory.name}>
                                            <td
                                                className={classNames(
                                                    inventoryIdx !== inventory.length - 1 ? 'border-b border-gray-200' : '',
                                                    'whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6 lg:pl-8'
                                                )}
                                            >
                                                {inventory.name}
                                            </td>
                                            <td
                                                className={classNames(
                                                    inventoryIdx !== inventory.length - 1 ? 'border-b border-gray-200' : '',
                                                    'whitespace-nowrap px-3 py-4 text-sm text-gray-500 hidden sm:table-cell'
                                                )}
                                            >
                                                {inventory.description}
                                            </td>
                                            <td
                                                className={classNames(
                                                    inventoryIdx !== inventory.length - 1 ? 'border-b border-gray-200' : '',
                                                    'whitespace-nowrap px-3 py-4 text-sm text-gray-500 hidden lg:table-cell'
                                                )}
                                            >
                                                {inventory.category.name}
                                            </td>
                                            <td
                                                className={classNames(
                                                    inventoryIdx !== inventory.length - 1 ? 'border-b border-gray-200' : '',
                                                    'whitespace-nowrap px-3 py-4 text-sm text-gray-500'
                                                )}
                                            >
                                                {inventory.customFields.custom1}
                                            </td>
                                            <td
                                                className={classNames(
                                                    inventoryIdx !== inventory.length - 1 ? 'border-b border-gray-200' : '',
                                                    'whitespace-nowrap px-3 py-4 text-sm text-gray-500'
                                                )}
                                            >
                                                {inventory.customFields.custom2}
                                            </td>
                                            <td
                                                className={classNames(
                                                    inventoryIdx !== inventory.length - 1 ? 'border-b border-gray-200' : '',
                                                    'whitespace-nowrap px-3 py-4 text-sm text-gray-500'
                                                )}
                                            >
                                                {Math.round(inventory.totalQuantityOnHand)}
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                            <div className='p-4 flex items-center justify-between w-full'>
                                {!!(skip > 0) && (
                                    <div className='flex items-center w-full grow justify-start'>
                                        <a href={`/?next=${+skip - 100 }`}>                                    
                                            <span className='inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-cyan-600 hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cyan-500'>
                                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4">
                                                    <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 19.5L8.25 12l7.5-7.5" />
                                                </svg>
                                                Previous
                                            </span>
                                        </a>
                                    </div>
                                )}
                                {!!(skip < 2987) && (
                                    <div className='flex items-center w-full grow justify-end'>
                                        <a href={`/?next=${+skip + 100 }`}>
                                            <span className='inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-cyan-600 hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cyan-500'>Next <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-4 h-4">
                                                <path strokeLinecap="round" strokeLinejoin="round" d="M8.25 4.5l7.5 7.5-7.5 7.5" />
                                                </svg>
                                            </span>
                                        </a>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>}
            </div>
        </div>
    )
}
