import { useEffect, useState } from 'react';
import Inventory from './components/inventory/inventory';
import Login from './components/login';

export default function App() {

  let [isLoggedIn, setIsLoggedIn] = useState(false);

  const onLoggedInHandler = () => {
    setIsLoggedIn(true);
    localStorage.setItem('isLoggedIn', true);
  }
  const onLoggedOutHandler = () => {
    setIsLoggedIn(false);
    localStorage.removeItem('isLoggedIn');
  }

  useEffect(() => {
    const storedUserLoggedInInfo = localStorage.getItem('isLoggedIn');    
    if (storedUserLoggedInInfo === 'true') {
      setIsLoggedIn(true);
    } else {
      setIsLoggedIn(false);
    }
  }, []);

  return (
    <>
      {!isLoggedIn && <Login onAuthenticate={onLoggedInHandler} />}
      {isLoggedIn && <Inventory onLogout={onLoggedOutHandler} />}
    </>
  )
}